/* DOM element styling */
html {
  height: 100%;
  background: #3B4954;
  font: -apple-system-body;
}

#root {
  animation: fadeIn 1.5s;
  background: #4E4F50;
}

a, a:hover, a:active, a:visited, a:focus {
  color: unset;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 30px 0 0 5px;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 15px;
  width: 80%;
}

p {
  font-family: 'Raleway', sans-serif;
}

/* Parallax and parallax layer styling */
.ParallaxContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: inherit;
  width: inherit;
  height: 100%;
}

.ParallaxStyle {
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;
  background: #3B4954;
  color: #C3CDD5;
  scrollbar-width: none;
}

.ParallaxStyle::-webkit-scrollbar { display: none;} 
.ParallaxStyle { -ms-overflow-style: none; }

.PictureContainer {
  display: flex;
  width: 50% !important;
  justify-content: center;
  align-items: center;
}

.StickyPicture {
  border-radius: 50%;
  width: clamp(300px, 25vw, 430px);
  filter: grayscale(100%);
}

.HeroLayer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.HeroContainer {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.ListText {
  font-family: 'Raleway', sans-serif;
  font-size: 1.1em;
}

.IconStyle {
  font-size: 1.3rem;
  width: auto;
}

.DownStyle {
  font-size: 1.5vw;
  font-weight: 100;
  animation: upAndDown 3s infinite;
  animation-delay: 3s;
  height: fit-content;
  width: fit-content;
  transition: 2s;
  color: #FFC857;
}

.DownStyle:hover {
  cursor: pointer;
  animation: none;
  font-size: 2vw;
  transition: .3s;
}

.HeaderText {
  font-size: clamp(15px, 1.7vw, 20px);
  color: #FFC857;
}

.NameHeader {
  font-size: 7vw;
  font-size: clamp(48px, 9vw, 110px);
  text-indent: -7px;
  font-weight: 600;
  line-height: 1.3;
}

.SubHeaderText {
  font-weight: 600;
  font-size: 2.4vw;
  font-size: clamp(15px, 2.6vw, 38px);
  opacity: .5;
}

.HeaderSummary{
  margin-top: 50px;
  width: 70%;
  font-size: clamp(15px, 1.7vw, 20px);
  font-weight: 200;
}

.Hero {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Poppins', sans-serif;
}

.AltSubHeader {
  font-size: clamp(15px, 1.7vw, 20px);
  margin-bottom: 6px;
  color: #FFC857;
}

.AltHeaderLayer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.AltHeaderText {
  font-size: clamp(35px, 4vw, 65px);
  font-weight: 700;
  color: #dce6ed;
}

.AltHeaderContainer {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  align-items: flex-end;
  height: 40vh;
  align-self: flex-start;
  font-family: 'Poppins', sans-serif;
}

.AltTextContainer {
  margin-top: 20px;
  width: 75%;
  font-family: 'Poppins', sans-serif;
}

.FlexEnd {
  display: flex;
  justify-content: flex-end;
}

.HeaderTextAlign {
  display: flex;
  justify-content: flex-end;
}

.AltDescriptionContainer {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  height: 60vh;
  width: 50%;
}

.AltText {
  letter-spacing: 1px;
  font-size: clamp(13px, 1.3vw, 20px);
  margin-top: 20px;
  font-weight: 300;
}

.JobTitle {
  display: inline-flex;
  color: #FFC857;
  transition: .3s;
  font-weight: 300;
}

.JobTitle:hover {
  color:#FFCF70;
  cursor: pointer;
}

.ButtonLayer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.ButtonContainer {
  justify-content: flex-start;
  width: 50%;
  height: 100%;
}

.ActionButton, .ResumeButton {
  font-weight: 500;
  font-size: clamp(13px, .8vw, 16px);
  padding: 1rem 1.5rem
}

.ActionButton {
  background-color: #FFC857;
  border-color: #FFC857;
  color: #4C5E6B;
}

.ResumeButton {
  background-color: #4C5E6B;
  border-color:#FFC857 ;
  color: #FFC857;
  margin-left: 20px;
}

.ActionButton:hover, .ActionButton:active, .ActionButton:visited, .ActionButton:focus {
  background-color: #FFCF70 !important;
  border-color: #FFCF70 !important;
  color: #556977;
  box-shadow: 1px 1px 3px #546878;
}

.ResumeButton:hover, .ResumeButton:active, .ResumeButton:visited, .ResumeButton:focus {
  background-color: #556977 !important;
  border-color: #FFCF70 !important;
  color: #FFCF70;
  box-shadow: 1px 1px 3px #546878;
}

.ButtonIcon {
  padding-bottom: 3px;
  font-size: clamp(14px, 1.1vw, 20px);
}

.HideElement {
  width: 0;
}

.LeftReveal {
  width: 65%;
  transition: .3s;
}

.ScrollHide {
  opacity: 0;
  font-size: 1.5rem;
}

.SocialContainer {
  display: flex;
  justify-content: flex-start;
}

.SocialIcon {
  font-size: 1.3vw;
  font-size: clamp(20px, 1.3vw, 25px);
}

.MobileAltHeader {
  display: none;
}

.MobileBackground {
  display: none;
}

.PicSizing {
  display: flex;
  justify-content: center;
}


@media screen and (min-width: 1400px){
  .container-xxl {
    max-width: 1920px;
  }
}

@media screen and (max-width: 1200px){
  .AltTextContainer {
    width: 80%;
    margin-top: 0;
  }
}

@media screen and (max-width: 992px){
  .AltTextContainer {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .AltHeaderContainer {
    align-items: flex-start;
    align-self: flex-end;
    width: 100%;
    height: 55%;
    margin-left: 50px;
    margin-right: 50px;
  }

  .AltHeaderText {
    padding-right: 2.1vw;
    font-size: 7vw;
    text-indent: -4px;
  }
  
  .AltTextContainer {
    width: 100%;
  }

  .HeaderTextAlign {
    justify-content: flex-start;
  }

  .AltDescriptionContainer {
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: clamp(120px, 28vw, 190px);
    margin-left: 50px;
    margin-right: 50px;
    margin-top: calc(109px - 8vw);
  }

  .AltText {
    font-size: clamp(17px, 2.3vw, 18px);
  }

  .PictureContainer {
    width: 100% !important;
    height: 45% !important;
    display: flex;
    align-items: center;
  }

  .PicSizing {
    height: 80%;
    width: auto;
  }

  .StickyPicture {
    height: 100%;
    width: inherit;
    object-fit: contain;
  }

  .HeroLayer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .HeroContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 55%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .HeaderText {
    font-size: clamp(15px, 2.8vw, 22px);
  }

  .NameHeader {
    font-size: clamp(54px, 9vw, 80px);
  }

  .SubHeaderText {
    font-size: clamp(18px, 4vw, 33px);
  }

  .HeaderSummary {
    margin-top: 30px;
    font-size: clamp(17px, 2.3vw, 18px);
    width: 100%;
  }

  .ButtonLayer {
    margin-left: 50px;
    margin-right: 50px;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .ButtonContainer {
    height: 90%;
    width: 100%;
  }

  .ActionButton, .ResumeButton {
    font-weight: 600;
    font-size: 15px;
    padding: 1rem 1.45rem;
  }

  .ButtonIcon {
    font-size: 1.25rem;
  }

  .SocialIcon {
    font-size: 1.5rem;
  }

  .DownStyle {
    font-size: 25px;
    display: block;
    text-align: center;
    height: 25px;
    width: 25px;
  }

  .DownStyle:hover, .DownStyle:active, .DownStyle:visited {
    font-size: 25px;
    transition: .3s;
  }

  .SideText {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .AltText {
    font-size: 1rem;
  }
  
}

@media screen and (max-width: 600px) {
  .MobileAltHeader{
    display: block;
    font-size: 8vw;
    text-align: center;
    font-weight: 600;
  }
  
  .HeaderSummary {
    margin-top: 35px;
  }

  .MobileHide {
    display: none;
  }

  .NameHeader {
    text-indent: -3px;
  }

  .HeroContainer {
    padding-left: 45px;
    padding-right: 45px;
  }

  .AltDescriptionContainer {
    height: calc(314px - 20vw);
    margin: 0;
    white-space: inherit;
  }

  .AltTextContainer {
    font-family: 'Poppins', sans-serif;
    width: unset;
    padding: 0 1.2rem;
  }

  .AltText {
    text-align: center;
    font-weight: 200;
  }

  .ButtonContainer {
    text-align: center;
  }

  .ActionButton, .ResumeButton {
    font-size: 4vw;
    padding: 1rem 2.5rem;
  }

  .ButtonLayer {
    margin: 0;
  }
  
  .MobileBackground {
    display: block;
  }

  .MobilePattern {
    height: 300%;
    width: 100%;
    background-image: url("./assets/45-degree-fabric-light.png");
    background-repeat: repeat;
    opacity: .25;
    clip-path: ellipse(290% 99% at 50% 100%);
  }
}

@media screen and (max-width: 400px) {
  .AltText {
     font-size: .9rem;
  }

  .AltDescriptionContainer {
    height: calc(300px - 20vw);
  }

  .HeroContainer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .NameHeader {
    font-size: 2.8rem;
    text-indent: -2px;
  }

  .SubHeaderText {
    font-size: 4.1vw;
  }

  .HeaderSummary {
    margin-top: 25px;
    font-size: 1rem;
  }

}

@media screen and (max-width: 315px) { 
  .HeaderText {
    font-size: 4vw;
  }
  
  .NameHeader {
    font-size: 13vw;
  }

  .HeroContainer {
    padding-left: 30px;
    padding-right: 30px;
  }

  .SubHeaderText {
    font-size: 4.1vw;
  }

  .HeaderSummary {
    margin-top: 25px;
    font-size: clamp(10px, 1.9vh, 16px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(15deg); }
  100% { transform: rotate(0deg); }
}

@keyframes fadeIn {
  from { opacity: 0%; }
  to { opacity: 100%; }
}

@keyframes upAndDown {
  0%, 10%, 50% {
    transform: translateY(0px);
    opacity: 1;
  }
  30%{
    transform: translateY(10px);
    opacity: .7;
  }
}
